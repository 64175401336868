<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="ListIcon" size="30" class="mr-50" />
                Todos os Componentes
              </h1>
              <h4 class="mt-1">Selecione um componente:</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-form @submit.prevent="fetchUsers">
      <b-row>
        <b-col md="12">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.firstName"
              placeholder="Buscar Paciente"
            />
          </b-input-group>
        </b-col>
        <!-- <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.lastName"
              placeholder="Útimo Nome do Paciente"
            />
          </b-input-group>
        </b-col>

        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.cpf"
              type="number"
              placeholder="CPF do Paciente"
            />
          </b-input-group>
        </b-col>

        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.city"
              placeholder="Cidade do Paciente"
            />
          </b-input-group>
        </b-col>

        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.state"
              placeholder="Estado do Paciente"
            />
          </b-input-group>
        </b-col> -->

        <!-- <b-col md="4">
          <flat-pickr
            v-model="filtro.createdAtTo"
            :config="configFlatPickr"
            class="form-control"
            name="date"
            placeholder="Criado em"
          />
        </b-col> -->

        <b-col md="4">
          <!-- <b-button type="submit" variant="primary" block @click="fetchUsers()">
            Filtrar
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>

    <b-card no-body class="mb-0">
      <b-table
        ref="refUserListTable"
        style="min-height: 50vw"
        class="position-relative"
        :items="fetchedUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(nome)="data">
          {{ data.item.physicalPerson.firstName }}
          {{ data.item.physicalPerson.lastName }}
        </template>

        <template #cell(cadastro)="data">
          {{ data.item.createdAt }}
        </template>

        <template #cell(localizacao)="data">
          {{ data.item.physicalPerson.person.city }} -
          {{ data.item.physicalPerson.person.state }}
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.physicalPerson.person.status
            )}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.physicalPerson.person.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{ name: 'pacienteEdit', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->
            <!--Mostrar apenas se tiver alguma consulta marcada -->
            <b-dropdown-item>
              <feather-icon icon="PlayIcon" />
              <span
                class="align-middle ml-50"
                onclick="location.href='/consulta'"
                >Iniciar para Consulta</span
              >
            </b-dropdown-item>

            <b-dropdown-item
              :to="{
                name: 'pacienteDetail',
                params: { pacienteId: data.item.id },
              }"
            >
              <feather-icon icon="FolderIcon" />
              <span class="align-middle ml-50">Prontuário</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'resultado' }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Resultados</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'pacienteEdit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar Paciente</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BLink,
  BMedia,
  BAvatar,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BForm,
} from 'bootstrap-vue'
import { title } from '@/@core/utils/filter'
import patientService from '@/services/patientService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BForm,
    flatPickr,
  },
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    filtro: {},
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    fetchedUsers: [],
    tableColumns: [
      { key: 'id', sortable: true },
      { key: 'physicalPerson.cpf', label: 'CPF', sortable: true },
      { key: 'nome', label: 'Nome', sortable: true },
      { key: 'cadastro', label: 'Cadastro', sortable: true },
      { key: 'localizacao', label: 'Localização', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions', label: 'Acesso', sortable: false },
    ],
  }),
  mounted() {
    this.fetchUsers()
  },
  methods: {
    prepareRequest(obj) {
      if (obj.createdAtTo) obj.createdAtTo = obj.createdAtTo ? `${obj.createdAtTo.split('/').reverse().join('-')} 00:00` : ''
      return obj
    },
    async fetchUsers() {
      // showSpinner()
      this.fetchedUsers = await patientService.getAll(this.prepareRequest(this.filtro))
      hideSpinner()
    },
    // resolveUserRoleVariant: role => {
    //   if (role === 'paciente') return 'primary'
    //   if (role === 'author') return 'warning'
    //   if (role === 'maintainer') return 'success'
    //   if (role === 'editor') return 'info'
    //   if (role === 'admin') return 'danger'
    //   return 'primary'
    // },
    // resolveUserRoleIcon: role => {
    //   if (role === 'paciente') return 'UserIcon'
    //   if (role === 'author') return 'SettingsIcon'
    //   if (role === 'maintainer') return 'DatabaseIcon'
    //   if (role === 'editor') return 'Edit2Icon'
    //   if (role === 'admin') return 'ServerIcon'
    //   return 'UserIcon'
    // },
    resolveUserStatusText: status => {
      if (status === 'abandono') return 'warning'
      if (status === 'ACTIVE') return 'ativo'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return ''
    },

    resolveUserStatusVariant: status => {
      if (status === 'abandono') return 'warning'
      if (status === 'ACTIVE') return 'success'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return 'primary'
    },
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
